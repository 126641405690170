import React, { Fragment } from "react";

import { format } from "date-fns";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import ReactCountryFlag from "react-country-flag";

import ClockHandle from "assets/images/activity-history-moving@2x.png";
import Clock from "assets/images/activity-history-still@2x.png";
import { ReactComponent as CopyPaste } from "assets/images/copy-paste-icon.svg";
import ShakeSDKError from "assets/images/details-pane-icons/shake-sdk-error-icon.svg";
import Shake from "assets/images/details-pane-icons/shake-sdk-icon.svg";
import ShakeSDK from "assets/images/details-pane-icons/shake-sdk-icon.svg";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import Paragraph from "components/Paragraph/Paragraph";
import displayToast from "components/Toast/displayToast";

import { AttributeOperator } from "hooks/filtering/sharedTypes";
import { getAvailableFieldNamesByString } from "hooks/filtering/utils";

import {
  App,
  AppUserShared,
  Attachment,
  getUserName,
  Permission,
  PlatformName,
  PlatformOs,
  SupportedFileTypes,
} from "models";
import { Blackbox, BlackboxChartType, BlackboxData, BlackboxNetworkData } from "models/blackbox";
import { CrashEvent } from "models/crashReporting";
import { getLabelWhenNotSent, Integration, IntegrationType } from "models/integrations";
import { Issue, IssueServiceHookPermalink } from "models/issueTracking";

import { getIntegrationTypeLogo } from "pages/Administration/util/getIntegrationTypeLogo";
import { getBrowserIcon, getOSIcon } from "pages/shared/icons/getIconsHelper";
import { MetadataIcon } from "pages/shared/icons/icons";
import * as Styled from "pages/shared/styles/DetailsWrapper.styles";

import { vars } from "styles";

import { mapFilesToAttachmentUrls } from "util/files";
import identifiers from "util/identifiers.json";
import { t } from "util/translator";

import { LinkElement } from "./DetailsPane";
import * as StyledPane from "./DetailsPane.styles";
import * as Icon from "./icons";

export interface InfoElement {
  iconComponent?: JSX.Element;
  icon?: string;
  text?: string;
  tip?: string;
  tipTitle?: string;
  isError?: boolean;
  onElementClick?: () => void;
  isButton?: boolean;
  Dropdown?: JSX.Element;
  testId?: string;
  clickable?: boolean;
  fullwidth?: boolean;
  subtitle?: string;
  setHover?: (hover: boolean) => void;
  disabled?: boolean;
  notClickable?: boolean;
  blackbox?: {
    key: BlackboxChartType;
    data?: BlackboxData[] | BlackboxNetworkData[];
    keyValue?: number;
  };
}

export interface GroupedTicketDetailsElement {
  title?: string;
  infoElements: (InfoElement | undefined)[];
}

export interface GroupedInfoElement {
  title: string;
  infoElements: Array<GroupedTicketDetailsElement | undefined>;
  link?: LinkElement;
}

export const getDetails = (
  ticket?: CrashEvent | Issue,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
  selectedApp?: App,
  addToFilterObject?: AddToFilterObject,
  blackBox?: Blackbox,
  detailsNums?: { aggs: { [key: string]: number }; total: number },
): Array<GroupedTicketDetailsElement | undefined> => {
  if (!ticket) return [];

  const isWeb = selectedApp?.platform.name === PlatformName.WEB;

  const ticketText = (value?: number) => {
    if (!value) return "";
    if (value > 1) return "tickets";
    return "ticket";
  };

  return [
    {
      title: "Basics",
      infoElements: [
        isWeb
          ? {
              tip: t("detailsPane.tooltip.currentView"),
              fullwidth: true,
              disabled: isElementDisabled(ticket.current_view),
              Dropdown: getDropdown(
                getDetailsText(ticket.current_view),
                t("detailsPane.tooltip.currentView"),
                <Icon.CurrentViewIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                ticket.current_view.length > 30 ? "top-left" : "top-right", //this is a hack, should me modified later
                undefined,
                addToFilterObject,
                ticket.current_view,
                undefined,
                undefined,
                undefined,
                undefined,
                findValueByKey("current_view", detailsNums?.aggs),
                `${findValueByKey("current_view", detailsNums?.aggs)} ${ticketText(
                  findValueByKey("current_view", detailsNums?.aggs),
                )} have this current view
${findValueByKey("current_view", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                  ((findValueByKey("current_view", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                  100
                ).toFixed(0)}%`,
                true,
              ),
            }
          : undefined,
        {
          tip: t("detailsPane.tooltip.time"),
          disabled: isElementDisabled(ticket.issue_reported_time),
          Dropdown: getDropdown(
            format(Date.parse(ticket.issue_reported_time), "LLL d, K:mm:ss a"),
            t("detailsPane.tooltip.time"),
            <Icon.ClockIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            "top-right",
          ),
        },
        {
          tip: t("detailsPane.tooltip.os"),
          disabled: isElementDisabled(ticket.pretty_os),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_os),
            t("detailsPane.tooltip.os"),
            <img
              src={getOSIcon(ticket.pretty_os)}
              width={20}
              height={20}
              style={{ marginRight: "1.2rem" }}
            />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            "top-left",
            undefined,
            addToFilterObject,
            !isWeb
              ? ticket.os_version
              : [
                  { tip: "os_version.full_version", value: ticket.os_version },
                  { tip: "os_name", value: ticket.os_name },
                  ticket.cpu_arch ? { tip: "cpu_arch", value: ticket.cpu_arch } : undefined,
                ],
            undefined,
            undefined,
            undefined,
            undefined,
            findValueByKey("os_version", detailsNums?.aggs),
            `${findValueByKey("os_version", detailsNums?.aggs)} ${ticketText(
              findValueByKey("os_version", detailsNums?.aggs),
            )} have this OS version
${findValueByKey("os_version", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
              ((findValueByKey("os_version", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
              100
            ).toFixed(0)}%`,
          ),
        },
        {
          tip: t("detailsPane.tooltip.device"),
          disabled: isElementDisabled(ticket.pretty_device_name),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_device_name),
            t("detailsPane.tooltip.device"),
            isWeb ? <Icon.DeviceWebIcon /> : <Icon.DeviceIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            "top-right",
            undefined,
            addToFilterObject,
            ticket.pretty_device_name,
            undefined,
            undefined,
            undefined,
            undefined,
            findValueByKey("device", detailsNums?.aggs),
            `${findValueByKey("device", detailsNums?.aggs)} ${ticketText(
              findValueByKey("device", detailsNums?.aggs),
            )} have this device
${findValueByKey("device", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
              ((findValueByKey("device", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
              100
            ).toFixed(0)}%`,
          ),
        },
        isWeb
          ? {
              tip: "Browser",
              disabled: isElementDisabled(ticket.pretty_browser),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_browser),
                "Browser",
                <img
                  src={getBrowserIcon(ticket.pretty_browser)}
                  width={20}
                  height={20}
                  style={{ marginRight: "1.2rem" }}
                />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                "top-left",
                undefined,
                addToFilterObject,
                [
                  { tip: "browser_name", value: ticket.browser_name },
                  { tip: "browser_version.full_version", value: ticket.browser_version },
                ],
              ),
            }
          : undefined,
        !isWeb
          ? {
              tip: t("detailsPane.tooltip.appVersion"),
              disabled: isElementDisabled(ticket.pretty_app_version),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_app_version),
                t("detailsPane.tooltip.appVersion"),
                <Icon.AppVersionIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                "top-left",
                undefined,
                addToFilterObject,
                ticket.app_version,
                undefined,
                undefined,
                undefined,
                undefined,
                findValueByKey("app_version", detailsNums?.aggs),
                `${findValueByKey("app_version", detailsNums?.aggs)} ${ticketText(
                  findValueByKey("app_version", detailsNums?.aggs),
                )} have this app version
${findValueByKey("app_version", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                  ((findValueByKey("app_version", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                  100
                ).toFixed(0)}%`,
              ),
            }
          : undefined,
        !isWeb
          ? {
              tip: t("detailsPane.tooltip.currentView"),
              disabled: isElementDisabled(ticket.current_view),
              Dropdown: getDropdown(
                getDetailsText(ticket.current_view),
                t("detailsPane.tooltip.currentView"),
                <Icon.CurrentViewIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                "top-right",
                undefined,
                addToFilterObject,
                ticket.current_view,
                undefined,
                undefined,
                undefined,
                undefined,
                findValueByKey("current_view", detailsNums?.aggs),
                `${findValueByKey("current_view", detailsNums?.aggs)} ${ticketText(
                  findValueByKey("current_view", detailsNums?.aggs),
                )} have this current view
${findValueByKey("current_view", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                  ((findValueByKey("current_view", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                  100
                ).toFixed(0)}%`,
              ),
            }
          : undefined,
        selectedApp?.platform.os === PlatformOs.IOS
          ? {
              tip: "IDE",
              disabled: isElementDisabled(ticket.pretty_xcode_version),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_xcode_version),
                "IDE",
                <Icon.IDEIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                undefined,
                undefined,
                addToFilterObject,
                ticket.pretty_xcode_version,
              ),
            }
          : undefined,
      ],
    },
    {
      title: "Whereabouts",
      infoElements: [
        {
          tip: t("detailsPane.tooltip.location"),
          disabled: isElementDisabled(ticket.pretty_location),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_location),
            t("detailsPane.tooltip.location"),
            getCountryIcon(ticket.country),
            detailDropdownOpened,
            setDetailDropdownOpened,
            "top-left",
            undefined,
            addToFilterObject,
            ticket.pretty_location ? ticket.pretty_location.split(",")[0] : "",
          ),
        },
        {
          tip: t("detailsPane.tooltip.timezone"),
          disabled: isElementDisabled(ticket.pretty_timezone),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_timezone),
            t("detailsPane.tooltip.timezone"),
            <Icon.TimezoneIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
          ),
        },
        {
          tip: t("detailsPane.tooltip.locale"),
          disabled: isElementDisabled(ticket.pretty_locale),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_locale),
            t("detailsPane.tooltip.locale"),
            <Icon.LanguageIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            "top-right",
            undefined,
            addToFilterObject,
            ticket.pretty_locale,
            undefined,
            undefined,
            undefined,
            undefined,
            findValueByKey("locale", detailsNums?.aggs),
            `${findValueByKey("locale", detailsNums?.aggs)} ${ticketText(
              findValueByKey("locale", detailsNums?.aggs),
            )} have this locale
${findValueByKey("locale", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
              ((findValueByKey("locale", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
              100
            ).toFixed(0)}%`,
          ),
        },
      ],
    },
    {
      title: "Connectivity",
      infoElements: [
        {
          tip: t("detailsPane.tooltip.network"),
          disabled: isElementDisabled(ticket.pretty_network_status),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_network_status),
            t("detailsPane.tooltip.network"),
            <Icon.NetworkIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            undefined,
            undefined,
            addToFilterObject,
            ticket.searchable_network_status,
          ),
        },
        selectedApp?.platform.os === PlatformOs.ANDROID
          ? {
              tip: "NFC",
              disabled: isElementDisabled(ticket.pretty_nfc),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_nfc),
                "NFC",
                <Icon.NfcIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                undefined,
                undefined,
                addToFilterObject,
                ticket.pretty_nfc,
              ),
            }
          : undefined,
      ],
    },
    {
      title: "Screen",
      infoElements: [
        {
          tip: t("detailsPane.tooltip.resolution"),
          disabled: isElementDisabled(ticket.pretty_resolution),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_resolution),
            t("detailsPane.tooltip.resolution"),
            <Icon.ResolutionIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
          ),
        },
        isWeb
          ? {
              tip: "Browser resolution",
              disabled: isElementDisabled(ticket.pretty_browser_resolution),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_browser_resolution),
                "Browser resolution",
                <Icon.BrowserResolutionIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
              ),
            }
          : undefined,
        isWeb
          ? {
              tip: "Website mode",
              disabled: isElementDisabled(ticket.is_mobile !== null ? ticket.is_mobile.toString() : ""),
              Dropdown: getDropdown(
                ticket.is_mobile !== null ? (ticket.is_mobile ? "Mobile" : "Desktop") : "Not available",
                "Website mode",
                <Icon.MobileDesktopIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                undefined,
                undefined,
                addToFilterObject,
                ticket.pretty_is_mobile,
              ),
            }
          : undefined,
        isWeb
          ? {
              tip: "Cookies",
              disabled: isElementDisabled(ticket.cookies_enabled !== null ? ticket.cookies_enabled.toString() : ""),
              Dropdown: getDropdown(
                ticket.cookies_enabled !== null ? (ticket.cookies_enabled ? "Enabled" : "Disabled") : "Not available",
                "Cookies",
                <Icon.CookiesIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                undefined,
                undefined,
                addToFilterObject,
                ticket.pretty_cookies_enabled,
              ),
            }
          : undefined,
        selectedApp?.platform.os === PlatformOs.ANDROID
          ? {
              tip: "Density",
              disabled: isElementDisabled(ticket.screen_density),
              Dropdown: getDropdown(
                getDetailsText(ticket.pretty_screen_density),
                "Density",
                <Icon.DensityIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
              ),
            }
          : undefined,
        {
          tip: t("detailsPane.tooltip.orientation"),
          disabled: isElementDisabled(ticket.pretty_device_orientation),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_device_orientation),
            t("detailsPane.tooltip.orientation"),
            <Icon.OrientationIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
            isWeb ? "top-right" : "top-left",
            undefined,
            addToFilterObject,
            ticket.pretty_device_orientation,
            undefined,
            undefined,
            undefined,
            undefined,
            findValueByKey("device_orientation", detailsNums?.aggs),
            `${findValueByKey("device_orientation", detailsNums?.aggs)} ${ticketText(
              findValueByKey("device_orientation", detailsNums?.aggs),
            )} have ${ticket?.pretty_device_orientation?.toLocaleLowerCase()} orientation
${findValueByKey("device_orientation", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
              ((findValueByKey("device_orientation", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
              100
            ).toFixed(0)}%`,
          ),
        },
        {
          tip: "Font size",
          disabled: isElementDisabled(ticket.pretty_font_scale),
          Dropdown: getDropdown(
            getDetailsText(ticket.pretty_font_scale),
            "Font size",
            <Icon.AccessibilityIcon />,
            detailDropdownOpened,
            setDetailDropdownOpened,
          ),
        },
      ],
    },
    {
      title: "Usage",
      infoElements: (
        [
          !isWeb
            ? {
                tip: "Authentication to unlock device",
                disabled: isElementDisabled(ticket.pretty_authentication),
                Dropdown: getDropdown(
                  getDetailsText(ticket.pretty_authentication),
                  "Authentication to unlock device",
                  <Icon.AuthIcon />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                  "top-right",
                  undefined,
                  addToFilterObject,
                  ticket.pretty_authentication,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  findValueByKey("authentication", detailsNums?.aggs),
                  `${findValueByKey("authentication", detailsNums?.aggs)} ${ticketText(
                    findValueByKey("authentication", detailsNums?.aggs),
                  )} have ${ticket.pretty_authentication?.toLocaleLowerCase()} authentication
${findValueByKey("authentication", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                    ((findValueByKey("authentication", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                    100
                  ).toFixed(0)}%`,
                ),
              }
            : undefined,
          {
            tip: "Battery status",
            disabled: isElementDisabled(ticket.pretty_battery_status),
            Dropdown: getDropdown(
              getDetailsText(ticket.pretty_battery_status),
              "Battery status",
              <Icon.BatteryIcon />,
              detailDropdownOpened,
              setDetailDropdownOpened,
            ),
          },
          !isWeb
            ? {
                tip: "Low power mode",
                disabled: isElementDisabled(ticket.pretty_low_power_mode),
                Dropdown: getDropdown(
                  getDetailsText(ticket.pretty_low_power_mode),
                  "Low power mode",
                  <Icon.LowPowerModeIcon />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                  "top-right",
                  undefined,
                  addToFilterObject,
                  ticket.pretty_low_power_mode,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  findValueByKey("low_power_mode", detailsNums?.aggs),
                  `${findValueByKey("low_power_mode", detailsNums?.aggs)}  ${ticketText(
                    findValueByKey("low_power_mode", detailsNums?.aggs),
                  )} have ${ticket.pretty_low_power_mode?.toLocaleLowerCase()} low power mode
${findValueByKey("low_power_mode", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                    ((findValueByKey("low_power_mode", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                    100
                  ).toFixed(0)}%`,
                ),
              }
            : undefined,
          !isWeb
            ? {
                tip: "Used storage",
                disabled: isElementDisabled(ticket.pretty_storage_status),
                Dropdown: getDropdown(
                  getDetailsText(ticket.pretty_storage_status),
                  "Used storage",
                  <Icon.StorageIcon />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                ),
              }
            : undefined,
          !isWeb
            ? {
                tip: "Memory used by device",
                disabled: isElementDisabled(ticket.pretty_memory_status),
                Dropdown: getDropdown(
                  getDetailsText(ticket.pretty_memory_status),
                  "Memory used by device",
                  <Icon.MemoryIcon />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                ),
              }
            : undefined,
          !isWeb
            ? {
                tip: "Memory used by your app",
                disabled: isElementDisabled(ticket.pretty_used_application_memory),
                Dropdown: getDropdown(
                  getDetailsText(ticket.pretty_used_application_memory),
                  "Memory used by your app",
                  <Icon.MemoryCodeIcon />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                ),
              }
            : undefined,
        ] as InfoElement[]
      ).concat(
        getPermissions(ticket.permissions, selectedApp?.platform.os, detailDropdownOpened, setDetailDropdownOpened),
      ),
    },
    ticket.blackbox
      ? {
          title: "Black box",
          infoElements: [
            !isWeb
              ? {
                  blackbox: {
                    key: BlackboxChartType.CPU,
                    data: blackBox?.cpu_usage_history,
                  },
                }
              : undefined,
            !isWeb
              ? {
                  blackbox: {
                    key: BlackboxChartType.MEMORY,
                    data: blackBox?.memory_usage_history,
                    keyValue: ticket.available_memory,
                  },
                }
              : undefined,
            {
              blackbox: {
                key: BlackboxChartType.APP_MEMORY,
                data: blackBox?.application_memory_usage_history,
              },
            },
            !isWeb
              ? {
                  blackbox: {
                    key: BlackboxChartType.STORAGE,
                    data: blackBox?.disk_space_usage_history,
                    keyValue: ticket.available_disk_space,
                  },
                }
              : undefined,
            {
              blackbox: {
                key: BlackboxChartType.BATTERY,
                data: blackBox?.battery_usage_history,
              },
            },
            { blackbox: { key: BlackboxChartType.NETWORK, data: blackBox?.network_usage_history } },
            {
              blackbox: {
                key: BlackboxChartType.ORIENTATION,
                data: blackBox?.device_orientation_history,
              },
            },
          ],
        }
      : undefined,
    {
      title: "Shake",
      infoElements: [
        {
          isError: ticket.sdk_version !== selectedApp?.platform.latest_version,
          tip: ticket.sdk_version !== selectedApp?.platform.latest_version ? "SDK not up to date" : "SDK up to date",
          disabled:
            isElementDisabled(ticket.sdk_version) || ticket.sdk_version === selectedApp?.platform.latest_version,
          iconComponent: (
            <img
              src={ShakeSDK}
              width={20}
              height={20}
              style={{ marginRight: "1.2rem" }}
            />
          ),
          text: getDetailsText(ticket.sdk_version),
          notClickable: true,
          Dropdown:
            ticket.sdk_version !== selectedApp?.platform.latest_version
              ? getShakeSDKDropdown(
                  getDetailsText(ticket.sdk_version),
                  "SDK not up to date",
                  ShakeSDKError,
                  {
                    text: "Docs • Update Shake",
                    link: getLatestVersionDocsLink(selectedApp?.platform.name as PlatformName),
                  },
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                  addToFilterObject,
                  ticket.sdk_version,
                  findValueByKey("sdk_version", detailsNums?.aggs),
                  `${findValueByKey("sdk_version", detailsNums?.aggs)} tickets have this sdk version
${findValueByKey("sdk_version", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                    ((findValueByKey("sdk_version", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                    100
                  ).toFixed(0)}%`,
                )
              : getDropdown(
                  getDetailsText(ticket.sdk_version),
                  "SDK up to date",
                  <img
                    src={ShakeSDK}
                    width={20}
                    height={20}
                    style={{ marginRight: "1.2rem" }}
                  />,
                  detailDropdownOpened,
                  setDetailDropdownOpened,
                  "top-left",
                  undefined,
                  addToFilterObject,
                  ticket.sdk_version,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  findValueByKey("sdk_version", detailsNums?.aggs),
                  `${findValueByKey("sdk_version", detailsNums?.aggs)} tickets have this sdk version
${findValueByKey("sdk_version", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                    ((findValueByKey("sdk_version", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                    100
                  ).toFixed(0)}%`,
                ),
        },
      ],
    },
    ticket.tester_email
      ? {
          title: "Deprecated",
          infoElements: [
            {
              isError: true,
              tip: "Email",
              disabled: isElementDisabled(ticket.pretty_tester_email),
              Dropdown: getDropdown(
                ticket.pretty_tester_email,
                "Email",
                <Icon.EmailDeprecatedIcon />,
                detailDropdownOpened,
                setDetailDropdownOpened,
                "top-left",
                undefined,
                addToFilterObject,
                ticket.pretty_tester_email,
                undefined,
                undefined,
                undefined,
                undefined,
                findValueByKey("tester_email", detailsNums?.aggs),
                `${findValueByKey("tester_email", detailsNums?.aggs)} tickets have this tester email
${findValueByKey("tester_email", detailsNums?.aggs)} out of ${detailsNums?.total} • ${(
                  ((findValueByKey("tester_email", detailsNums?.aggs) ?? 0) / (detailsNums?.total ?? 1)) *
                  100
                ).toFixed(0)}%`,
              ),
            },
          ],
        }
      : undefined,
  ];
};

export const resolveAttachmentUrls = (ticket: CrashEvent | Issue) => {
  const featuredUrls = [ticket.screenshot_url, ticket.video_url].filter((url) => !!url);
  const otherFileUrls = mapFilesToAttachmentUrls(ticket.files);

  return [...featuredUrls, ...otherFileUrls];
};

export const emptyArrayFor1Attach = (size: "large" | "small", platform?: PlatformName): Attachment[] => {
  let attachments: Attachment[] = [];
  if (size === "large") {
    attachments = [
      { type: SupportedFileTypes.TXT, isHelper: true, num: 4 },
      { num: 5 },
      { num: 6 },
      { type: SupportedFileTypes.TXT, num: 7 },
    ];
  }
  if (size === "small") {
    attachments = [{ type: SupportedFileTypes.TXT, isHelper: true, num: 2 }, { num: 3 }, { num: 4 }];
  }
  if (platform === PlatformName.WEB) {
    attachments = attachments.map((a) => {
      a.isHelper = false;
      return a;
    });
  }
  return attachments;
};

export const getPermissions = (
  permissions: Permission[],
  os?: PlatformOs,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
): InfoElement | [] => {
  let permissionsString = "";
  permissions.map((permission, index) => {
    return (permissionsString = `${permissionsString}${index !== permissions.length - 1 && index !== 0 ? `,` : ""} ${
      permission.pretty_name
    }`);
  });

  return {
    tip: os === PlatformOs.ANDROID ? "Granted dangerous permissions" : "Granted permissions",
    Dropdown: getDropdown(
      permissionsString !== "" ? permissionsString : "None",
      os === PlatformOs.ANDROID ? "Granted dangerous permissions" : "Granted permissions",
      <Icon.GrantedPermission />,
      detailDropdownOpened,
      setDetailDropdownOpened,
    ),
  } as InfoElement;
};

export const readMetadata = (
  metadata: { [key: string]: string | boolean } & { first_name?: string; last_name?: string },
  keysToOmit?: string[],
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
  addToFilterObject?: {
    addToFilter: (attributeName: string, filterValue: string) => void;
    redirectURL: string;
  },
): InfoElement[] => {
  if (!metadata) return [];
  return Object.keys(metadata)
    .filter((key) => !keysToOmit?.includes(key))
    .map((key) => ({
      Dropdown: getDropdown(
        metadata[key]?.toString(),
        key,
        <MetadataIcon />,
        detailDropdownOpened,
        setDetailDropdownOpened,
        undefined,
        key,
        addToFilterObject,
        metadata[key]?.toString(),
        undefined,
        undefined,
        !!validURL(metadata[key]?.toString()),
        true,
      ),
      fullwidth: true,
      disabled: isElementDisabled(metadata[key]?.toString()),
    }));
};

const getDetailsText = (text?: string) => {
  if (text) return text;
  return "Not available";
};

export const getDropdown = (
  value: string,
  tip: string,
  iconComponent?: JSX.Element,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
  numTooltipPos?: "top-left" | "top-right", //this is a hack, should me modified later
  label?: string,
  addToFilterObject?: AddToFilterObject,
  filterValue?: string | ({ tip: string; value: string } | undefined)[],
  icon?: {
    icon: string;
    errorIcon: string;
    isError: boolean;
  },
  disabled?: boolean,
  showOpenInNewTab?: boolean,
  isMetadata?: boolean,
  num?: number,
  numTooltipText?: string,
  fullWidth?: boolean,
) => {
  const isOpened = detailDropdownOpened === tip;

  const setIsOpened = (isOpened: boolean) => {
    if (isOpened) {
      return setDetailDropdownOpened?.(tip);
    }
  };

  return (
    <Styled.DropdownContainer>
      <Dropdown
        iconComponent={iconComponent}
        label={!label ? value : undefined}
        icon={icon ? (icon.isError ? icon.errorIcon : icon.icon) : undefined}
        labelComponent={getLabelComponent(value, label)}
        buttonElement={<Fragment />}
        position="left"
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        disabled={isElementDisabled(value) || disabled}
        horizontalOffset={2}
        num={num}
        numTooltipText={numTooltipText}
        numTooltipPos={numTooltipPos}
        fullWidth={fullWidth}
      >
        <DropdownItem
          wide
          onClick={() => {
            navigator.clipboard.writeText(value);
            displayToast({
              content: `${tip} copied to the clipboard`,
            });
            setDetailDropdownOpened?.(undefined);
          }}
        >
          <CopyPaste
            height={20}
            width={20}
          />
          Copy to clipboard
        </DropdownItem>

        {showOpenInNewTab && (
          <div style={{ width: "100%", marginTop: "0.8rem" }}>
            <div style={{ borderTop: `1px solid ${vars.colors.grey90}`, marginBottom: "0.8rem" }}>
              <div style={{ marginLeft: "2rem", marginTop: "0.8rem" }}>
                <Paragraph
                  color={vars.colors.grey60}
                  fontSize={12}
                >
                  Link detected
                </Paragraph>
              </div>
            </div>
            <DropdownItem
              wide
              onClick={() => {
                window.open(value, "_blank")?.focus();
                setDetailDropdownOpened?.(undefined);
              }}
            >
              <Icon.NewTab
                height={20}
                width={20}
              />
              Open link in a new tab
            </DropdownItem>
          </div>
        )}

        {addToFilterObject && filterValue && (
          <DropdownItem
            wide
            onClick={() => {
              if (Array.isArray(filterValue)) {
                filterValue.map((fil, index) => {
                  if (fil)
                    addToFilterObject.addToFilter(
                      getAvailableFieldNamesByString(fil.tip, isMetadata),
                      fil.value,
                      index !== 0 ? "and" : undefined,
                    );
                });
              } else {
                addToFilterObject.addToFilter(getAvailableFieldNamesByString(tip, isMetadata), filterValue);
              }

              setDetailDropdownOpened?.(undefined);

              window.open(addToFilterObject.redirectURL, "_blank")?.focus();
            }}
          >
            <StyledPane.Search
              height={20}
              width={20}
            />
            Add to search
          </DropdownItem>
        )}
      </Dropdown>
    </Styled.DropdownContainer>
  );
};

const getLabelComponent = (value: string, label?: string) => {
  if (!label) return;
  return (
    <Fragment>
      <StyledPane.Label>{label}</StyledPane.Label>
      <StyledPane.Value>{value}</StyledPane.Value>
    </Fragment>
  );
};

type AddToFilterObject = {
  addToFilter: (attributeName: string, filterValue: string, attrOperator?: AttributeOperator) => void;
  redirectURL: string;
};

export const getAppUserInfoElements = (
  appUser?: AppUserShared,
  onClick?: () => void,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
) => {
  if (appUser) {
    return [
      {
        infoElements: [
          {
            tip: "User name",
            fullwidth: true,
            disabled: isElementDisabled(getUserName(appUser)),
            Dropdown: getDropdown(
              getUserName(appUser),
              "User name",
              <Icon.UserIcon />,
              detailDropdownOpened,
              setDetailDropdownOpened,
            ),
          },
          {
            tip: "User ID",
            fullwidth: true,
            disabled: isElementDisabled(appUser.end_user_id),
            Dropdown: getDropdown(
              appUser.end_user_id,
              "User ID",
              <Icon.UserIdIcon />,
              detailDropdownOpened,
              setDetailDropdownOpened,
            ),
          },
          {
            text: "View full profile",
            onElementClick: () => {
              if (appUser) onClick?.();
            },
            isButton: true,
            disabled: !appUser,
          },
        ] as InfoElement[],
      },
    ];
  }
  return [];
};

export const getIntegrationDropdown = (props: IntegrationDropdownProps) => {
  const {
    integrationDropdownOpened,
    integration,
    setIntegrationDropdownOpened,
    sendingIntegrationMap,
    sendIssue,
    serviceHook,
  } = props;

  const isOpened = integrationDropdownOpened === integration.type;

  const setIsOpened = (isOpened: boolean) => {
    if (isOpened) {
      return setIntegrationDropdownOpened?.(integration.type);
    }
  };
  const isSending = sendingIntegrationMap.get(integration.id);

  if (!serviceHook) {
    return (
      <Styled.DropdownContainer>
        <Dropdown
          icon={getIntegrationTypeLogo(integration.type)}
          label={isSending ? "Sending..." : getLabelWhenNotSent(integration.type)}
          buttonElement={<Fragment />}
          position="left"
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          horizontalOffset={2}
        >
          <DropdownItem
            wide
            onClick={() => {
              setIntegrationDropdownOpened?.(undefined);
              sendIssue(integration);
            }}
            testId={identifiers["details.pane.integration.button.send"]}
          >
            <Icon.SendIcon
              height={20}
              width={20}
            />
            Send
          </DropdownItem>
          <DropdownItem
            wide
            onClick={() => {
              setIntegrationDropdownOpened?.(undefined);
              props.onClickIntegrationSettings();
            }}
            testId={identifiers["details.pane.integration.button.resend"]}
          >
            <Icon.GrantedPermission
              height={20}
              width={20}
            />
            Integration settings
          </DropdownItem>
        </Dropdown>
      </Styled.DropdownContainer>
    );
  }

  return (
    <Styled.DropdownContainer>
      <Dropdown
        icon={getIntegrationTypeLogo(integration.type)}
        label={isSending ? "Sending..." : getServiceHookTextByType(serviceHook)}
        buttonElement={<Fragment />}
        position="left"
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        horizontalOffset={2}
      >
        <DropdownItem
          wide
          onClick={() => {
            setIntegrationDropdownOpened?.(undefined);
            window.open(serviceHook.permalink);
          }}
        >
          <Icon.NewTab
            height={20}
            width={20}
          />
          Open in a new tab
        </DropdownItem>
        <DropdownItem
          wide
          onClick={() => {
            setIntegrationDropdownOpened?.(undefined);
            handleCopyLink(serviceHook.permalink);
          }}
        >
          <CopyPaste
            height={20}
            width={20}
          />
          Copy link
        </DropdownItem>
        <DropdownItem
          wide
          onClick={() => {
            setIntegrationDropdownOpened?.(undefined);
            sendIssue(integration);
          }}
          testId={identifiers["details.pane.integration.button.resend"]}
        >
          <Icon.ResendIcon
            height={20}
            width={20}
          />
          Resend
        </DropdownItem>
        <DropdownItem
          wide
          onClick={() => {
            setIntegrationDropdownOpened?.(undefined);
            props.onClickIntegrationSettings();
          }}
          testId={identifiers["details.pane.integration.button.resend"]}
        >
          <Icon.GrantedPermission
            height={20}
            width={20}
          />
          Integration settings
        </DropdownItem>
      </Dropdown>
    </Styled.DropdownContainer>
  );
};

export interface IntegrationDropdownProps {
  integration: Integration;
  serviceHook?: IssueServiceHookPermalink;
  integrationDropdownOpened?: IntegrationType;
  setIntegrationDropdownOpened?: (op?: IntegrationType) => void;
  sendIssue: (integration: Integration) => void;
  sendingIntegrationMap: Map<string, boolean>;
  onClickIntegrationSettings: () => void;
}

const handleCopyLink = (value?: string) => {
  if (!value) return;
  navigator.clipboard.writeText(value);
  displayToast({
    title: "Fantastic!",
    content: "Permalink has been copied to the clipboard",
  });
};

const getServiceHookTextByType = (serviceHook: IssueServiceHookPermalink) => {
  switch (serviceHook.service_hook_type) {
    case IntegrationType.JIRA:
    case IntegrationType.GITHUB:
      const permalinkArray = serviceHook.permalink.split("/");
      return permalinkArray[permalinkArray?.length - 1];
    default:
      return "Permalink";
  }
};

export const getClockIcon = () => {
  return (
    <StyledPane.Parent>
      <StyledPane.Image1 src={Clock} />
      <StyledPane.Image2 src={ClockHandle} />
    </StyledPane.Parent>
  );
};

export const isElementDisabled = (text: string) => {
  return text === "Not available" || !text;
};

export const getShakeSDKDropdown = (
  value: string,
  tip: string,
  icon: string,
  linkItem: {
    link: string;
    text: string;
  },
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
  addToFilterObject?: AddToFilterObject,
  filterValue?: string,
  num?: number,
  numTooltipText?: string,
) => {
  const isOpened = detailDropdownOpened === tip;

  const setIsOpened = (isOpened: boolean) => {
    if (isOpened) {
      return setDetailDropdownOpened?.(tip);
    }
  };

  return (
    <Styled.DropdownContainer>
      <Dropdown
        label={value}
        icon={icon}
        buttonElement={<Fragment />}
        position="left"
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        horizontalOffset={2}
        num={num}
        numTooltipText={numTooltipText}
        numTooltipPos="top-right"
      >
        <DropdownItem
          wide
          onClick={() => {
            window.open(linkItem.link, "_blank")?.focus();
            setDetailDropdownOpened?.(undefined);
          }}
        >
          <img
            src={Shake}
            width={20}
            height={20}
            style={{ marginRight: "1rem", color: vars.colors.purple }}
          />
          {linkItem.text}
        </DropdownItem>

        {addToFilterObject && filterValue && (
          <DropdownItem
            wide
            onClick={() => {
              addToFilterObject.addToFilter(getAvailableFieldNamesByString(tip), filterValue);
              setDetailDropdownOpened?.(undefined);

              window.open(addToFilterObject.redirectURL, "_blank")?.focus();
            }}
          >
            <StyledPane.Search
              height={20}
              width={20}
            />
            Add to search
          </DropdownItem>
        )}
      </Dropdown>
    </Styled.DropdownContainer>
  );
};

export const getExtensionsDropdown = (
  value: string,
  tip: string,
  icon: string,
  linkItem: {
    link: string;
    text: string;
  },
  isDisabled: boolean,
  detailDropdownOpened?: string,
  setDetailDropdownOpened?: (detailDropdownOpened?: string) => void,
  num?: number,
  numTooltipText?: string,
) => {
  const isOpened = detailDropdownOpened === tip;

  const setIsOpened = (isOpened: boolean) => {
    if (isOpened) {
      return setDetailDropdownOpened?.(tip);
    }
  };
  return (
    <Styled.DropdownContainer>
      <Dropdown
        label={value}
        icon={icon}
        buttonElement={<Fragment />}
        position="left"
        isOpened={isOpened}
        disabled={isDisabled}
        setIsOpened={setIsOpened}
        horizontalOffset={2}
        num={num}
        numTooltipText={numTooltipText}
      >
        <DropdownItem
          wide
          onClick={() => {
            window.open(linkItem.link, "_blank")?.focus();
            setDetailDropdownOpened?.(undefined);
          }}
        >
          <Icon.NewTab
            height={20}
            width={20}
          />
          Open in a new tab
        </DropdownItem>
      </Dropdown>
    </Styled.DropdownContainer>
  );
};

const getLatestVersionDocsLink = (platform: PlatformName) => {
  switch (platform) {
    case PlatformName.ANDROID:
      return "https://docs.shakebugs.com/docs/android/installation/#add-shake-dependency-to-your-app-level-buildgradle-file";
    case PlatformName.IOS:
      return "https://docs.shakebugs.com/docs/ios/install/spm/#install-the-shake-package";
    case PlatformName.FLUTTER:
      return "https://docs.shakebugs.com/docs/flutter/installation/#install";
    default:
      return "https://docs.shakebugs.com/docs/react/install/npm/#install";
  }
};

function validURL(str: string) {
  const urlRegex = new RegExp(
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
  );

  return str?.match(urlRegex);
}

const getCountryIcon = (country: string) => {
  if (!country) return <Icon.LocationIcon />;
  countries.registerLocale(enLocale);
  const code = countries.getAlpha2Code(country, "en");

  return (
    <Fragment>
      {code ? (
        <ReactCountryFlag
          countryCode={code}
          svg
          style={{ height: "2rem", width: "2rem", marginRight: "1.2rem" }}
        />
      ) : (
        <Icon.LocationIcon />
      )}
    </Fragment>
  );
};

function findValueByKey(keyName: string, data?: { [key: string]: number }): number | undefined {
  if (!data) return;
  let value;

  if (data.hasOwnProperty(keyName)) {
    value = data[keyName];
  }

  return value ?? undefined;
}
